import { PageHeading } from '@/components/base/page-heading';
import Section from '@/components/base/section';
import { Skeleton } from '@/components/base/skeleton';
import {
  useGetFundedAccountsQuery,
  useGetUserWithdrawalPaymentMethodsQuery,
  useRequestBrokerWithdrawalMutation,
  WithdrawalPaymentMethod,
  type FundedAccountFragment,
  type PaymentMethodDetailsFragment,
} from '@graphql/index';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useIntl } from 'react-intl';
import invariant from 'tiny-invariant';
import { z } from 'zod';
import { ChooseAmount } from './choose-amount';
import { CompleteRequest } from './complete-request';
import { SelectAccount } from './select-account';
import { SelectPayoutMethod } from './select-payout-method';
import { SuccessfulRequest } from './success';
import { Summary } from './summary';

function ErrorMessage({ message }: { message: string }): JSX.Element {
  let component = <>{message}</>;

  if (message === 'CONSISTENCY_NEEDED') {
    component = (
      <Trans
        i18nKey="payout.consistencyRules"
        components={{
          faq: (
            // eslint-disable-next-line jsx-a11y/anchor-has-content -- filled in lang
            <a
              rel="noopener"
              className="font-bold underline"
              href="https://help.monevis.com/en/articles/9961165-gambling-behaviour-on-funded-accounts"
              target="_blank"
            />
          ),
        }}
      />
    );
  }
  return (
    <div className="rounded-lg border border-red-700 bg-red-800/10 p-4 text-sm">
      {component}
    </div>
  );
}

export function RequestPayout({
  fundedAccounts,
  accountLogin,
  paymentMethods,
}: {
  fundedAccounts: FundedAccountFragment[];
  accountLogin?: string;
  paymentMethods: PaymentMethodDetailsFragment[];
}): JSX.Element {
  function getSelectedAccount(
    login: string,
  ): FundedAccountFragment | undefined {
    return fundedAccounts.find((item) => item.login === login);
  }

  const intl = useIntl();
  const currency = 'USD';
  const [selectedAccount, setSelectedAccount] = useState<string>(
    accountLogin ?? '',
  );
  const [amountToWithdraw, setAmountToWithdraw] = useState<number>(0);

  const [currentAccount, setCurrentAccount] = useState<FundedAccountFragment>();
  const [requestWithdrawal] = useRequestBrokerWithdrawalMutation();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setCurrentAccount(getSelectedAccount(accountLogin ?? ''));
  }, [accountLogin]);

  function handleChangeAccount(login: string): void {
    setSelectedAccount(login);
    setCurrentAccount(getSelectedAccount(login));
  }

  const payoutSchema = z.object({
    amount: z
      .string()
      .min(1)
      .max(currentAccount?.withdrawable ?? 0, {
        message: t('requestPayoutPage.maxAmountError'),
      }),
    paymentMethodId: z
      .string()
      .min(1, { message: 'Payment method is required' }),
  });

  type Payout = z.infer<typeof payoutSchema>;
  const methods = useForm<Payout>({
    resolver: zodResolver(payoutSchema),
    mode: 'onChange',
    values: {
      amount: currentAccount?.withdrawable.toString() ?? '',
      paymentMethodId: '',
    },
  });
  const { setValue, watch, handleSubmit } = methods;
  const watchAmount = watch('amount');
  const avaibleAmount = !Number(watchAmount) ? 0 : Number(watchAmount);
  const watchPaymentMethod = watch('paymentMethodId');
  const minRiseAmount = 500;
  const currentPayoutMethod = paymentMethods.find(
    (item) => item.id === watchPaymentMethod,
  );
  const withdrawable = Number(watchAmount);
  const profitSplit = getSelectedAccount(selectedAccount)?.profitSplit;

  function calculateProfitSplit(): number {
    return Number(withdrawable) * (profitSplit ?? 0);
  }

  const riseUnderMinAmount =
    calculateProfitSplit() < minRiseAmount &&
    currentPayoutMethod?.paymentMethod === WithdrawalPaymentMethod.Rise;

  const cryptoAboveMaxAmount =
    calculateProfitSplit() > minRiseAmount &&
    currentPayoutMethod?.paymentMethod === WithdrawalPaymentMethod.DirectCrypto;

  const riseMethodCreated = paymentMethods.find(
    (item) => item.paymentMethod === WithdrawalPaymentMethod.Rise,
  );

  useEffect(() => {
    setValue('amount', '');
  }, [fundedAccounts, setValue]);

  function eligible(): boolean {
    if (currentAccount) {
      return (
        (currentAccount.eligibleForWithdrawal ?? false) &&
        Number(currentAccount.withdrawable) > 0
      );
    }
    return false;
  }

  async function withdraw(): Promise<void> {
    setErrorMessage('');
    try {
      const { data } = await requestWithdrawal({
        variables: {
          amount: Number(watchAmount),
          id: currentAccount?.id ?? '',
          methodDataId: currentPayoutMethod?.id ?? '',
        },
        refetchQueries: ['GetFundedAccounts'],
      });
      setAmountToWithdraw(data?.withdrawFromBrokerAccount?.amount ?? 0);
      setShowSuccessDialog(true);
    } catch (e) {
      // @ts-expect-error -- has error
      // eslint-disable-next-line -- has error
      setErrorMessage(e?.message ?? '');
      enqueueSnackbar({
        message: 'Something went wrong',
        variant: 'error',
      });
    }
  }

  return (
    <FormProvider {...methods}>
      <Section
        variant="secondary"
        className="mx-auto max-w-xl text-default-gray-950 dark:text-white"
      >
        <div className="flex w-full flex-col gap-6">
          <PageHeading subheading={t('payout.newSubheading')}>
            {t('payout.requestNewButton')}
          </PageHeading>

          <SelectAccount
            data={fundedAccounts}
            selectedAccount={selectedAccount}
            onChange={handleChangeAccount}
            eligibleForWithdrawal={eligible()}
            nextEligiblePayout={currentAccount?.nextEligiblePayout ?? ''}
          />

          <ChooseAmount
            onChange={(value) => {
              setValue('amount', value);
            }}
            amount={watchAmount}
            eligible={!eligible()}
            withdrawable={currentAccount?.withdrawable ?? 0}
            error={methods.formState.errors.amount?.message}
          />

          <div className="flex w-full flex-col justify-between">
            <PageHeading subheading={t('payout.withdrawOptions')} />
            <SelectPayoutMethod
              riseUnderMinAmount={riseUnderMinAmount}
              cryptoAboveMaxAmount={cryptoAboveMaxAmount}
              paymentMethods={paymentMethods}
              eligible={!eligible()}
              riseMethodCreated={Boolean(riseMethodCreated)}
            />
          </div>

          <Summary profitSplit={profitSplit} withdrawable={withdrawable ?? 0} />

          <PageHeading>{t('payout.availableAmount')}</PageHeading>
          <div
            data-testid="available-amount"
            className="flex items-start justify-center"
          >
            <span className="text-center text-4xl font-bold sm:text-6xl">
              {intl.formatNumber(avaibleAmount, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
            <span>{currency}</span>
          </div>

          <div className="flex w-full flex-col text-center text-sm">
            <span>{t('payout.bankTransferNote')}</span>
            <span>{t('payout.processingTimeNote')}</span>
          </div>

          {errorMessage && <ErrorMessage message={errorMessage} />}

          <CompleteRequest
            id={currentAccount?.id}
            amount={Number(watchAmount)}
            accountName={currentAccount?.accountName}
            disabled={
              !eligible() ||
              Number(watchAmount) < 1 ||
              riseUnderMinAmount ||
              !riseMethodCreated ||
              cryptoAboveMaxAmount
            }
            onSubmit={() => {
              handleSubmit(withdraw)();
            }}
          />
          <SuccessfulRequest
            onClose={() => {
              setShowSuccessDialog(false);
            }}
            open={showSuccessDialog}
            amount={amountToWithdraw}
          />
        </div>
      </Section>
    </FormProvider>
  );
}

export function RequestPayoutFormWithData({
  accountLogin,
}: {
  accountLogin?: string;
}): JSX.Element {
  const { data: methods, loading: methodsLoading } =
    useGetUserWithdrawalPaymentMethodsQuery({
      fetchPolicy: 'cache-and-network',
    });

  const { data, loading } = useGetFundedAccountsQuery();
  if (loading || methodsLoading) {
    return (
      <div className="mx-auto h-full w-fit">
        <Skeleton className="h-full w-full min-w-[300px] rounded-xl sm:min-w-[600px]" />
      </div>
    );
  }

  invariant(data?.fundedAccounts, `Funded accounts missing`);
  invariant(
    methods?.me.userWithdrawalPaymentMethods,
    `Payment methods missing`,
  );

  return (
    <RequestPayout
      accountLogin={accountLogin}
      fundedAccounts={data.fundedAccounts}
      paymentMethods={
        methods.me.userWithdrawalPaymentMethods?.filter(
          (method) => method !== null,
        ) ?? []
      }
    />
  );
}
