import { PageHeading } from '@/components/base/page-heading';

import { Button } from '@/components/base/button';
import { FormField } from '@/components/base/form/form-field';
import { Input } from '@/components/base/form/input';
import Section from '@/components/base/section';
import { TetherTrc20 } from '@/components/icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export enum Crypto {
  TRC20 = 'TRC20',
}

export const cryptoDataMap = {
  [Crypto.TRC20]: {
    label: 'USDT(TRC20)',
    icon: <TetherTrc20 height={70} width={70} viewBox="0 0 240 200" />,
    value: 'TRC20',
  },
  '': { label: '', icon: null, value: '' },
};

export function CryptoDetailsForm({
  handleNext,
  handleBack,
  loading,
}: {
  handleNext?: () => void;
  handleBack?: () => void;
  loading?: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  const methods = useFormContext();
  const {
    formState: { errors },
    watch,
  } = methods;

  const watchCrypto = watch('crypto') as Crypto;
  const watchTargetAddress = watch('targetAddress') as string;
  const watchTargetAddressConfirmation = watch(
    'targetAddressConfirm',
  ) as string;
  const watchDisplayName = watch('displayName') as string;

  const disableSubmit =
    Object.keys(errors).length > 0 ||
    !watchTargetAddress ||
    !watchTargetAddressConfirmation ||
    !watchDisplayName;

  return (
    <div
      data-testid="crypto-details-form"
      className="flex w-full justify-center"
    >
      <Section
        variant="secondary"
        className="w-fit min-w-[350px] md:min-w-[630px]"
      >
        <div className="flex w-full flex-col gap-6">
          <div className="flex items-center gap-4">
            {cryptoDataMap[watchCrypto].icon}
            <PageHeading className="mb-0">
              {cryptoDataMap[watchCrypto].label}
              {t('requestPayout.cryptoInfoTitle')}
            </PageHeading>
          </div>
          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.cryptoInfoAddressLabel')}
            name="targetAddress"
            defaultValue={watchTargetAddress}
          >
            <Input
              data-testid="cryptoInfoAddress"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.cryptoInfoAddressPlaceholder')}
            />
          </FormField>

          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.cryptoInfoAddressConfirmLabel')}
            name="targetAddressConfirm"
            defaultValue={watchTargetAddressConfirmation}
          >
            <Input
              data-testid="cryptoInfoAddressConfirm"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.cryptoInfoAddressPlaceholder')}
            />
          </FormField>

          <FormField
            labelClassName="dark:text-white text-default-gray-950"
            label={t('requestPayout.displayNameLabel')}
            name="displayName"
            defaultValue={watchDisplayName}
          >
            <Input
              data-testid="crypto-display-name"
              inputSize="sm"
              variant="secondary"
              placeholder={t('requestPayout.displayNamePlaceholder')}
            />
          </FormField>

          <div className="flex w-full justify-between gap-8">
            <Button
              onClick={() => {
                handleBack && handleBack();
              }}
              variant="secondary"
              size="sm"
              className="w-full"
            >
              {t('requestPayout.buttonBack')}
            </Button>
            <Button
              loading={loading}
              disabled={disableSubmit}
              onClick={handleNext}
              className="w-full"
              size="sm"
            >
              {t('requestPayout.buttonAdd')}
            </Button>
          </div>
        </div>
      </Section>
    </div>
  );
}
